import {
  QueryErrorResetBoundary,
  useQueryErrorResetBoundary,
} from "@tanstack/react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import ErrorFallback from "@/components/ErrorFallback";
import { FallbackType } from "@/types";
type Props = {
  errorBoundaryType: FallbackType;
  children: React.ReactNode;
};
const ErrorBoundaryWrapper = ({ errorBoundaryType, children }: Props) => {
  const { reset } = useQueryErrorResetBoundary();
  const fallbackRender = (props: FallbackProps) => {
    return <ErrorFallback {...props} fallbackType={errorBoundaryType} />;
  };
  return (
    <QueryErrorResetBoundary>
      <ErrorBoundary fallbackRender={fallbackRender} onReset={reset}>
        {children}
      </ErrorBoundary>
    </QueryErrorResetBoundary>
  );
};

export default ErrorBoundaryWrapper;
