import Footer from "@/components/Footer";
import Header from "@/components/Header";
import ErrorBoundaryWrapper from "@/lib-client/providers/ErrorBoundaryWrapper";
import { MeProvider } from "@/lib-client/providers/Me";
import SuspenseWrapper from "@/lib-client/providers/SuspenseWrapper";

type PageLayoutProps = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <>
      <ErrorBoundaryWrapper errorBoundaryType="screen">
        {/* <SuspenseWrapper loaderType="screen"> */}
        <MeProvider>
          <Header />
          <ErrorBoundaryWrapper errorBoundaryType="page">
            {/* {children} */}
            {/* <SuspenseWrapper loaderType="page"> */}
            {children}
            {/* </SuspenseWrapper> */}
          </ErrorBoundaryWrapper>
          <Footer />
        </MeProvider>
        {/* </SuspenseWrapper> */}
      </ErrorBoundaryWrapper>
    </>
  );
};
export default PageLayout;
