import * as React from "react";
import { createContext, useContext, useMemo } from "react";

import { User } from "@supabase/supabase-js";

import { chalk, log } from "@/utils/log";

import { useGetMe } from "../react-query/auth/useGetMe";

type MeContextType = {
  me: User | null;
  isLoading: boolean;
};
const MeContext = createContext<MeContextType>({ me: null, isLoading: true });

type MeProviderProps = {
  children: React.ReactNode;
};

//only send me data
export const MeProvider = ({ children }: MeProviderProps) => {
  const { data, isLoading } = useGetMe();
  log(chalk.yellow("MeProvider data", typeof data));
  const me = data ?? null;
  // const memoChildren = useMemo(() => children, [me]);
  return (
    <MeContext.Provider value={{ me, isLoading }}>
      {children}
    </MeContext.Provider>
  );
};

export const useMe = () => {
  const context = useContext(MeContext);
  if (context === undefined) {
    throw new Error("useMe must be used within a MeProvider");
  }
  return context;
};
