import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FallbackProps } from "react-error-boundary";

import { FallbackType } from "@/types";

type Props = {
  fallbackType: FallbackType;
} & FallbackProps;
const ErrorFallback = ({ error, fallbackType, resetErrorBoundary }: Props) => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-rose-600"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">
                Something Went Wrong!
              </p>
              <p className="mt-1 text-sm text-gray-500">UI: {fallbackType}</p>
              <p className="mt-1 text-sm text-gray-500">
                Message: {error.message}
              </p>
              <div className="mt-3 flex space-x-7">
                <button
                  type="button"
                  onClick={() => {
                    resetErrorBoundary();
                  }}
                  className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Try Again
                </button>
                <button
                  type="button"
                  className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Back to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
