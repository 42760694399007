const QueryKeys = {
    TESTS: 'tests',
    TEST: 'test',
    SUBTESTS: 'subtests',
    SUBTEST: 'subtest',
    Me: 'me',
    ORDERS: 'orders',
    ORDER: 'order',
    PROFILE: 'profile',
} as const

export type QueryKeys = typeof QueryKeys[keyof typeof QueryKeys]

export const filterEmptyQueryKeys = (queryKey: Array<string | number | undefined | null>) => {
    return queryKey.filter((key) => key || key === 0) as Array<string | number>
}

export default QueryKeys