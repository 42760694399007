import * as React from "react";

interface ILogo {}

const Logo = ({}: ILogo) => {
  return (
    <div className=" tracking-wide text-lg lg:text-2xl font-semibold text-accent leading-none block">
      New Paradigm Cognitive Testing
    </div>
  );
};

export default Logo;
