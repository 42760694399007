const testConfig = {
    requireAuth: true,
    submitToDb: true,
    testMode: process.env.NODE_ENV === 'development',
    product: {
        casa: {
            price_id: 'price_1MwG4bB1IRszQQ41v4CnCPlM'
        },
        anva: {
            price_id: 'price_1MwG7eB1IRszQQ41SnCGDhcy',

        }
    },
    test: {
        expiryDays: 30
    },
    flashingTime: 4
}


export default testConfig
