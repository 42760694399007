const Footer = () => {
  return (
    <div className="py-3 lg:py-6 bg-theme">
      <div className="container max-w-4xl mx-auto">
        <div className="text-sm  leading-none flex text-gray-50 justify-center space-x-2 flex-wrap text-center">
          <span>Copyright© NPIQTest.com {new Date().getFullYear()}.</span>
          <span>Privacy Policy.</span>
          <span>Disclaimer.</span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
