import { useSession, useSessionContext, useUser } from "@supabase/auth-helpers-react"
import { useQuery } from "@tanstack/react-query"

import { log } from "@/utils/log"

import QueryKeys from "../queryKeys"
import { supabaseClient } from "../supabase"

const getMe = async (id: string | undefined) => {
    if (!id) return null
    const { data: { user }, error } = await supabaseClient.auth.getUser()
    //maybe need to add user profile as well
    log("getMe", { user, error })

    //not sure if this is the right way to handle this
    if (error) throw error
    return user
}

export const useGetMe = () => {
    const { session, isLoading, error } = useSessionContext()
    const id = session?.user?.id
    log("useMe", { session, isLoading, error, id })
    const query = useQuery(
        {
            queryKey: [QueryKeys.Me, id],
            queryFn: () => getMe(id),
            // if session is loading, then we don't want to run the query
            enabled: !isLoading && !!session,

            // error means, have session id, but id is not valid
            // so we need to invalidate the session
            // maybe user is deleted from db
            onError: async (error) => {
                console.error('useMe query error', error)
                // invalidate user session
                await supabaseClient.auth.signOut()
            },

        }
    )
    return query
}