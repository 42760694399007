import * as React from "react";

import { Popover, Transition } from "@headlessui/react";
// import { UserAddIcon, XIcon } from "@heroicons/react/outline";
import {
  ArrowRightCircleIcon,
  Bars3Icon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";

import { useMe } from "@/lib-client/providers/Me";
import { log } from "@/utils/log";

import Logo from "./Logo";

const excludeSurveyNotificationPaths = ["/survey", "report"];

interface IHeader {}
const Header = ({}: IHeader) => {
  const router = useRouter();
  const { me } = useMe();
  // const { testOrders, surveyComplete } = useUser();
  // const supaUser = useSupaUser();

  // const [mounted, setMounted] = useState(false);
  // React.useEffect(() => setMounted(true), []);

  // if (!mounted) return null;

  return (
    <>
      <header className="bg-theme relative">
        <Popover className="relative ">
          <div className="flex justify-between items-center md:justify-start md:space-x-10  max-w-9xl mx-auto px-4 py-4 md:py-6 sm:px-8 lg:px-16 ">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <div>
                <span className="sr-only">Casa</span>
                <Link
                  href="/"
                  passHref
                  className=" cursor-pointer text-center leading-tight"
                >
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-stone-400 hover:text-stone-500 hover:bg-stone-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <Link
                href="/"
                passHref
                className="text-lg  cursor-pointer font-bold tracking-wide rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500"
              >
                Home
              </Link>
              <Link
                href="/about"
                passHref
                className="text-lg cursor-pointer  font-bold tracking-wide rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500"
              >
                About
              </Link>

              <Link
                href="/contact"
                passHref
                className="text-lg cursor-pointer font-bold tracking-wide rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500"
              >
                Contact
              </Link>
            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              {me ? (
                <>
                  <Link
                    href="/profile"
                    passHref
                    className="inline-flex items-center whitespace-nowrap cursor-pointer  tracking-wide  font-bold rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500"
                  >
                    My Profile
                    <UserCircleIcon
                      className="ml-2 -mr-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>
                  <Link href="/logout">
                    <div className="ml-6 inline-flex items-center cursor-pointer whitespace-nowrap  tracking-wide  font-bold rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500">
                      Sign Out
                      <ArrowRightCircleIcon
                        className="ml-2 -mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    href="/sign-in"
                    passHref
                    className="cursor-pointer whitespace-nowrap  tracking-wide  font-bold rounded-2xl px-4 py-1 bg-stone-900 text-accent hover:text-amber-500"
                  >
                    Sign in / Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>

          <Transition
            as={React.Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-accent border border-accent ring-opacity-5 bg-stone-200 divide-y-2 divide-stone-50">
                <div className="pt-3 pb-4 px-4 bg-theme">
                  <div className="flex items-center justify-between">
                    <div>
                      <Link href="/" passHref>
                        <Logo />
                      </Link>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-stone-400 hover:text-stone-500 hover:bg-stone-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="pt-3 pb-6 px-4">
                  <div className="flex flex-col space-y-3">
                    <Link
                      href="/about"
                      passHref
                      className="cursor-pointer font-bold tracking-wide  text-stone-600 hover:text-theme"
                    >
                      About
                    </Link>
                    <Link
                      href="/"
                      passHref
                      className="  cursor-pointer font-bold tracking-wide  text-stone-600 hover:text-theme"
                    >
                      Tests
                    </Link>
                    <Link
                      href="/contact"
                      passHref
                      className=" cursor-pointer font-bold tracking-wide  text-stone-600 hover:text-theme"
                    >
                      Contact
                    </Link>
                  </div>
                  <div className="mt-6">
                    {/* {supaUser ? (
                      <>
                        <Link
                          href="/profile"
                          passHref
                          className="whitespace-nowrap flex items-center cursor-pointer text-sm tracking-wide  font-bold text-stone-500 hover:text-stone-900"
                        >
                          <UserCircleIcon
                            className="mr-2 -ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          My Profile
                        </Link>
                        <Link href="/logout">
                          <div className="mt-4 flex items-center md:mt-0 md:ml-8 cursor-pointer whitespace-nowrap text-sm tracking-wide  font-bold text-stone-500 hover:text-stone-900">
                            <ArrowRightCircleIcon
                              className="mr-2 -ml-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            Sign Out
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          href="/sign-in"
                          passHref
                          className="w-full flex items-center justify-center font-bold bg-gradient-to-r from-stone-600 to-stone-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base  text-accent hover:from-stone-700 hover:to-stone-700"
                        >
                          Sign In / Sign up
                        </Link>
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        {/* {testOrders &&
        !surveyComplete &&
        excludeSurveyNotificationPaths.indexOf(router.pathname) === -1 ? (
          <div className="relative bg-accent">
            <div className="mx-auto max-w-7xl py-3 px-4 sm:px-6 lg:px-8">
              <div className="pr-16 sm:px-16 sm:text-center">
                <p className="font-semibold ">
                  <span className="inline">
                    Please complete the mandatory survey in order to receive
                    test report.
                  </span>
                  <span className=" ml-2 inline-block">
                    <Link
                      href={{
                        pathname: "/survey",
                        query: { redirect: router.asPath },
                      }}
                      passHref
                      className="font-bold  underline"
                    >
                      Complete my profile
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : null} */}
      </header>
    </>
  );
};

export default Header;
