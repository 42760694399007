import { useEffect, useRef } from 'react';

import chalk from 'chalk';

import testConfig from '../config/testConfig';

const log = (...args) => testConfig.testMode && console.log(...args);



const useLogOnce = (...args: Parameters<typeof log>) => {
    const ref = useRef(false)
    useEffect(() => {
        if (!ref.current) {
            ref.current = true
            log(...args)
        }
    }, [args])
}
//  log.red = logRed
export { log, useLogOnce, chalk }